import React, { Fragment } from 'react';
import styled from 'styled-components';

import { pageHeroTypes } from '../types/propTypes';
import { Container, Inner } from './Elements';
import ImgSrc from '../images/page-hero.svg';
import { font } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: url('${ImgSrc}');
  background-size: cover;
  background-position: center;
  ${Container} {
    position: relative;
    text-align: center;
    padding: 18rem 0;

    ${mq.tabletSmall`
      padding: 12rem 2rem;
    `}

    h1 {
      ${font.display};
    }
  }
`;

export default function PageHero({ title }) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <h1>{title}</h1>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

PageHero.propTypes = pageHeroTypes;
