import React, { Fragment } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { Container, Inner } from './Elements';
import { font, colors } from '../consts/style';
import mq from '../style/mq';
import SEO from './SEO';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Inner} {
    width: 100%;
    max-width: 90rem;

    .heading {
      text-align: center;
      h3 {
        ${font.h2};
      }
    }
    .body {
      max-width: 55rem;
      width: 100%;
      margin: auto;
      text-align: center;
      padding-top: 1.2rem;
      padding-bottom: 3.2rem;
      ${font.p};
    }

    form {
      display: flex;
      flex-wrap: wrap;
      transition: all 0.4s ease;

      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }

      label {
        ${font.p};
      }

      input,
      textarea {
        width: 100%;
        background: ${colors.dark};
        border: 0.2rem solid ${colors.darkPurple};
        border-radius: 0.4rem;
        padding: 0.4rem 0.8rem;
        color: white;
        ${font.p};
      }

      textarea {
        height: 22rem;
      }

      .input-wrap {
        width: 100%;
        margin-bottom: 1.6rem;
      }

      .input-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .input-wrap {
          width: 49%;
          margin-right: 2%;
          &:nth-child(2n) {
            margin-right: 0;
          }
          ${mq.mobile`
            width: 100%;
            margin-right: 0;
          `}
        }
      }
    }
    .captcha {
      margin: 0 auto;
    }
    .button {
      width: 100%;
      text-align: center;
      button {
        width: 100%;
        max-width: 30rem;
        margin-top: 1.2rem;
        ${font.button};
      }
    }
  }
`;

const contactQuery = graphql`
  {
    datoCmsApply {
      heading
      body
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default function ContactApply() {
  const { datoCmsApply } = useStaticQuery(contactQuery);

  return (
    <Fragment>
      <SEO meta={datoCmsApply.seoMetaTags} />
      <Wrapper>
        <Container>
          <Inner>
            <div className="heading">
              <h3>{datoCmsApply.heading}</h3>
            </div>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: datoCmsApply.body }}
            />
            <div className="form">
              <iframe
                title="Apply Form"
                width="100%"
                height="1680"
                src="https://app.smartsheet.com/b/form/1eca7e56d595454cb0eaf78287eae0a2"
              ></iframe>
            </div>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
