import React, { Fragment } from 'react';
import styled from 'styled-components';
import PageHero from '../components/PageHero';
import ContactApply from '../components/ContactApply';

const Wrapper = styled.div``;

export default function ApplyPage() {
  return (
    <Fragment>
      <Wrapper>
        <PageHero title="Welcome to the Writer’s Room" />
        <ContactApply />
      </Wrapper>
    </Fragment>
  );
}
